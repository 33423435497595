import { Box, HStack, Image, Link, Text } from '@chakra-ui/react';
import deepWaves from '../../static/vectors/footer-deep-waves.png';
import Container from '../../layouts/Container';
import zerolend from '../../static/vectors/zerolend.png';

const Footer = () => {
  return (
    <Box bgImage={deepWaves} position={'relative'} className={'footer-bg'}>
      <HStack pos={'absolute'} bottom={0}>
        <Image src={zerolend} width={'100vw'} zIndex={-1} />
        {/*<Marquee pauseOnHover={true} pauseOnClick={true} speed={250} >
          <Image
            src={zerolend}
            width={'100vw'}
          />
          <Image
            src={zerolend}
            width={'100vw'}
          />
          <Image
            src={zerolend}
            width={'100vw'}
          />
        </Marquee>*/}
      </HStack>
      <Container>
        <Box pb={{ base: 52, md: 72 }}>
          <Box display={{ base: 'block', md: 'flex' }} alignItems={'baseline'}>
            <Box flex={0.7} mb={{ base: 12, md: 0 }}>
              <Text
                fontWeight={600}
                fontFamily={'body'}
                fontSize={{ base: 42, md: 72 }}
                lineHeight={'120%'}
                className={'black-gradient-text'}
                mb={{ base: 2, md: 4 }}
              >
                Step into the <br /> void with ZeroLend
              </Text>
              <Text
                color={'white'}
                opacity={0.6}
                maxW={'372px'}
                fontSize={{ base: 18, md: 20 }}
                mb={8}
              >
                Join ZeroLend as we revolutionize the lending experience on L2s
              </Text>
            </Box>
            <Box flex={0.3}>
              <Box
                w={{ base: '100%', md: 'max-content' }}
                mx={{ base: 0, md: 'auto' }}
              >
                <Text opacity={0.3} mb={2}>
                  Links
                </Text>
                <Box
                  display={{ base: 'flex', md: 'block' }}
                  alignItems={'center'}
                  justifyContent={{ base: 'flex-start', md: 'space-between' }}
                  flexWrap={'wrap'}
                >
                  <Link
                    mb={3}
                    href={'https://twitter.com/zerolendxyz'}
                    target={'_blank'}
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>Twitter</Text>
                  </Link>
                  <Link
                    mb={3}
                    href={'https://discord.gg/zerolend'}
                    target={'_blank'}
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>Discord</Text>
                  </Link>
                  <Link
                    mb={3}
                    href={'https://github.com/zerolend'}
                    target={'_blank'}
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>Github</Text>
                  </Link>
                  <Link
                    mb={3}
                    href={'https://docs.zerolend.xyz'}
                    target={'_blank'}
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>Docs</Text>
                  </Link>
                  <Link
                    mb={3}
                    href={'https://roadmap.zerolend.xyz/'}
                    target={'_blank'}
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>Roadmap</Text>
                  </Link>

                  <Link
                    mb={3}
                    href={'https://defillama.com/protocol/zerolend'}
                    target={'_blank'}
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>DefiLlama</Text>
                  </Link>
                  <Link
                    mb={3}
                    href={'https://mirror.xyz/0xb32591b2D8b8129702A4214CE344D2F87A9250F2'}
                    target={'_blank'}
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>Blog</Text>
                  </Link>
                  <Link
                    mb={3}
                    href={'/files/brand-kit.zip'}
                    target={'_blank'}
                    download
                    pr={{ base: 4, md: 0 }}
                  >
                    <Text>Brand kit</Text>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
